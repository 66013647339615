<template>
  <div class="pt-4 pb-2">
    <h1 class="dark-text font-weight-semi-bold fz-28 px-4 px-md-0">History</h1>
    <div class="light-text fz-14 font-weight-regular d-flex mb-4 px-4 px-md-0">
      <v-btn
        v-if="checkDevice && phoneNumbers.length"
        :style="{ opacity: isFreeAccount ? '0.5' : 1 }"
        text
        class="text-none d-flex align-center px-2 font-weight-regular"
        height="36px"
        @click="checkNumber('call')"
      >
        <v-icon class="mr-1" color="#919EAB">phone</v-icon>
        <span class="light-text">New call</span>
      </v-btn>
      <v-btn
        v-if="phoneNumbers.length && currentCompany.sms_enabled"
        :style="{ opacity: isFreeAccount ? '0.5' : 1 }"
        text
        class="text-none ml-2 d-flex align-center px-2 font-weight-regular"
        height="36px"
        @click="checkNumber('message')"
      >
        <v-icon class="mr-1 rotate-180" color="#919EAB">message</v-icon>
        <span class="light-text ">New message</span>
      </v-btn>
    </div>
    <v-card :loading="loading" class="fz-14" :style="{ 'min-height': loading ? '4px' : 'unset' }">
      <v-list v-if="!loading && history.total" class="py-0">
        <template v-for="(item, itemIndex) in history.items">
          <v-divider
            v-if="itemIndex"
            :key="itemIndex + 'v-divider'"
          ></v-divider>
          <v-list-item
            @click="checkNumber('toChat', item)"
            :key="itemIndex + 'v-list-item'" class="px-5"
            :class="{'v-list-item--marked': (item.missedCall || item.unreadMessage)}"
          >
            <v-list-item-avatar width="50px" height="50px" class="my-4 mr-3">
              <v-img
                :alt="`${ item.ani } avatar`"
                :src="'https://picsum.photos/200?random=' + itemIndex"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="fz-14">{{ getName(item) }}</v-list-item-title>

              <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="flex-row align-center v-list-item__action--normal">
              <v-tooltip v-if="item.missedCall" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20px" color="red">phone_missed</v-icon>
                  </v-btn>
                </template>
                <span>Missed call</span>
              </v-tooltip>

              <v-tooltip v-if="item.unreadMessage" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20px" color="red">message</v-icon>
                  </v-btn>
                </template>
                <span>Unread message</span>
              </v-tooltip>
            </v-list-item-action>

            <v-list-item-action class="flex-row align-center v-list-item__action--hover">
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="checkNumber('toChat', item)"
                  >
                    <v-icon color="#919EAB">phone</v-icon>
                  </v-btn>
                </template>
                <span>Make a call</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="checkNumber('toVideoChat', item)"
                  >
                    <v-icon color="#919EAB">videocam</v-icon>
                  </v-btn>
                </template>
                <span>Make a video call</span>
              </v-tooltip>

              <v-tooltip v-if="getContactUuid(item)" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="$router.push({ name: 'UserProfile', params: { type: 'contactDetails', id: getContactUuid(item) } })"
                  >
                    <v-icon color="#919EAB">person</v-icon>
                  </v-btn>
                </template>
                <span>User information</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-divider/>
        <div v-if="history.total > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex">
            <template v-for="index in countPage">
              <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>
          <div class="ma-5 br-3 grey-button d-flex">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon small>
                navigate_before
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon small>
                navigate_next
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-list>
      <v-list v-if="!loading && !history.total">
        <v-list-item class="d-flex align-center justify-center fz-16 pa-5">History is empty</v-list-item>
      </v-list>
    </v-card>
    <vue-snotify/>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'History',
  data: () => ({
    loading: false,
    itemsPerPage: 10,
    page: 1,
    history: {
      items: [],
      total: 0
    },
    contactList: [],
    checkDevice: true
  }),
  computed: {
    ...mapGetters(['currentCompany', 'phoneNumbers']),
    isFreeAccount () { return this.currentCompany?.is_free },
    countPage () {
      return Math.ceil(this.history.total / this.itemsPerPage)
    }
  },
  watch: {
    page () {
      this.loadMessages()
    }
  },
  created () {
    this.loadMessages()
    this.loadContacts()
    this.didNumbersList({})
    this.checkIsDeviceEnableOnPC()
  },
  methods: {
    ...mapMutations('components', ['setSubscribeDialog']),
    ...mapActions(['getMessages', 'getContacts', 'didNumbersList']),
    async loadMessages () {
      try {
        this.loading = true
        const params = {
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          smsDir: 'all',
          orderType: 'DESC',
          orderBy: 'created_at'
        }
        const { data } = await this.getMessages(params)
        if (!data || !data.payload || !data.payload.items) return
        this.history = data.payload
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async loadContacts () {
      try {
        const params = {
          take: 9999
        }
        const { data } = await this.getContacts(params)
        if (!data || !data.contactList) return
        this.contactList = data.contactList
      } catch (err) {
        throw new Error(err)
      }
    },
    checkIsDeviceEnableOnPC () {
      navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
        this.checkDevice = true
      }).catch((err) => {
        this.checkDevice = false
        console.error(`you got an error: ${err}`)
      })
    },
    getName (item) {
      const number = item.type === 'in' ? item.ani : item.dnis
      const contact = this.contactList.find(e => e.phoneNumber.replace(/\D/g, '') === number)
      return contact ? `${contact.firstName ? contact.firstName : ''} ${contact.lastName ? contact.lastName : ''}` : number
    },
    getContactUuid (item) {
      const number = item.type === 'in' ? item.ani : item.dnis
      const contact = this.contactList.find(e => e.phoneNumber.replace(/\D/g, '') === number)
      return contact ? contact.contact_uuid : ''
    },
    toChat (userInfo) {
      this.$store.dispatch('components/userInfo', userInfo)
      this.$router.push({ name: 'Chat', query: { n: userInfo.type === 'in' ? userInfo.ani : userInfo.dnis } })
    },
    toVideoChat (userInfo) {
      this.$router.push({ name: 'Chat', params: { isCalling: true, isVideo: true } })
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    },
    checkNumber (type, data) {
      if (this.isFreeAccount) {
        if (this.phoneNumbers.length) {
          this.setSubscribeDialog(true)
        } else {
          this.$snotify.error('Please add number to make a call.')
        }
        return
      }
      this[type](data)
    },
    call () {
      this.$store.dispatch('components/keypadStatus', true)
    },
    message () {
      this.$router.push({ name: 'Chat', params: { isNew: true } })
    }
  }
}
</script>
