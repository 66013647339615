<template>
  <div class="pt-4 pb-2" style="position:relative;">
    <vue-snotify/>
    <router-link
      to="/history"
      class="back-link px-3 px-md-0"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      History
    </router-link>
    <audio style="display: none" id="remoteAudio" ref="remoteAudio" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <audio style="display: none" id="localAudio" ref="localAudio" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <h1 class="dark-text font-weight-semi-bold fz-28 mb-1 px-4 px-md-0">
      <template v-if="isNew">
        <label class="fz-14 mb-1 d-block">Enter a phone number</label>
        <v-text-field
          v-model="user.phoneNumber"
          @input="changePhoneNumber"
          solo
          outlined
          label="Phone number"
          type="text"
          :rules="phoneRules"
          dense
          hide-details
          @blur="checkAccountByPhoneNumber(user.phoneNumber)"
        ></v-text-field>
      </template>
      <template v-if="listOfDidNumber.length > 0">
          <label class="fz-14 ">Send as </label>
          <v-select
            v-model="selectedDidNumber"
            :items="listOfDidNumber"
            class="pa-0 cursor-pointer"
            menu-props="offsetY"
            hide-details
            label="Select"
            solo
            outlined
            dense
          >
            <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
            </template>
          </v-select>
      </template>

<!--      <template v-else>-->
<!--        {{ fullName || user.phoneNumber }}-->
<!--      </template>-->
    </h1>

    <div class="d-flex mb-4 px-2 px-md-0 flex-wrap-mobile" v-if="getPhoneNumber">
      <v-btn
        text
        :disabled="voiceCall"
        class="text-none grey--text text--darken-1 mr-6 align-center pr-0 pl-1"
        height="36px"
        v-if="checkDevice && !voiceCall"
        @click="toCall"
      >
          <v-icon
            size="20px"
            color="#919EAB"
            class="mr-1"
          >
              phone
          </v-icon>

          Call
      </v-btn>

      <v-btn
        :color="informationComponent ? '#5c6ac4' : '#919EAB'"
        text
        class="text-none mr-6 d-flex align-center pr-0 pl-1"
        height="36px"
        @click="informationComponentTrigger"
      >
        <v-icon
          :color="informationComponent ? '#5c6ac4' : '#919EAB'"
          size="20px"
          class="mr-1"
        >
          person
        </v-icon>

        Information
      </v-btn>

      <!-- <more-action-component :items="['Edit information', 'Remove']"/> -->

      <template v-if="voiceCall">
        <v-btn
          v-if="user && user.checked"
          text
          class="text-none mr-6 d-flex align-center pr-0 pl-1"
          height="36px"
          @click="recordComponentButton(recordComponent = !recordComponent)"
          :color="recordComponent ? '#5c6ac4' : '#919EAB'"
        >
          <v-icon
            size="20px"
            :color="recordComponent ? '#5c6ac4' : '#919EAB'"
            class="mr-1"
          >
            {{ recordComponent ? 'stop' : 'radio_button_checked' }}
          </v-icon>

          {{ recordComponent ? 'Stop' : 'Record' }}
        </v-btn>

<!--        <v-btn-->
<!--          text-->
<!--          class="text-none mr-6 d-flex align-center pr-0 pl-1"-->
<!--          height="36px"-->
<!--          @click="sendComponentButton(sendComponent = !sendComponent)"-->
<!--          :color="sendComponent ? '#5c6ac4' : '#919EAB'"-->
<!--        >-->
<!--          <v-icon-->
<!--            size="20px"-->
<!--            :color="sendComponent ? '#5c6ac4' : '#919EAB'"-->
<!--            class="mr-1"-->
<!--          >-->
<!--            send-->
<!--          </v-icon>-->

<!--          Send-->
<!--        </v-btn>-->

        <v-btn
          text
          class="text-none d-flex align-center pr-0 pl-1"
          height="36px"
          color="#ED6347"
          @click="hangup"
        >
          <v-icon
            size="20px"
            color="#ED6347"
            class="mr-1"
          >
            call_end
          </v-icon>

          Hang up
        </v-btn>
      </template>
    </div>
    <!-- HIDE for a while -->
    <!-- height="calc(100vh - 230px)" -->
    <v-card height="calc(100vh - 350px)" class="d-flex flex-wrap fill-width flex-column" v-if="getPhoneNumber">
      <v-col
        cols="12"
        md="12"
        class="d-flex align-center pa-5"
        style="flex: 0;"
      >
        <v-img
          :alt="`${fullName} avatar`"
          :src="'https://picsum.photos/200?random=1'"
          max-width="50px"
          max-height="50px"
          class="rounded-circle mr-4"
        ></v-img>

        <div class="d-flex flex-column justify-center">
          <span class="fz-16 dark--text font-weight-semi-bold">{{ fullName || 'Name this contact' }}</span>

          <span class="fz-14 grey--text">{{ formatPhone(user.phoneNumber) }}</span>
        </div>
      </v-col>

      <v-divider class="fill-width"></v-divider>

      <div v-if="callIsActive && !callReceived" class="w-100 px-5 py-2 white--text" style="background-color: #50B83C">
        <v-icon
          size="22px"
          color="#fff"
          class="mr-2"
        >
          phone
        </v-icon>
        <time>{{ timeDisplay }}</time>
      </div>
      <!-- HIDE for a while -->
      <v-sheet
        class="d-flex flex-column justify-end pb-4 fill-width"
        :class="isCallOutbound ? 'overflow-y-hidden' : 'overflow-y-auto'"
        style="position: relative; flex: 1;"
        ref="msgBlock"
      >
        <div
          class="px-4"
          style="height: 100%; max-height: 100%;"
        >
          <template v-if="chat.messageList.length">
            <template v-for="(group, groupIndex) in groupByDate">
              <v-tooltip top content-class="v-tooltip--top" :key="groupIndex">
                <template v-slot:activator="{ on }">
                  <div
                      v-on="on"
                      class="fz-12 grey--text text--darken-1 text-center mb-4 mt-8"
                  >
                    {{ formatChatDate(groupIndex) }}
                  </div>
                </template>
                <span>{{ groupIndex }}</span>
              </v-tooltip>

              <div
                v-for="(message, index) in group"
                :key="index + groupIndex"
                class="pb-4 d-flex"
                :class="message.type === 'in' ? 'justify-space-between' : 'justify-end'"
              >
                <div class="d-flex flex-column">
                  <template v-if="message.text">
                    <div
                      class="pb-5 pt-2 px-5 rounded-xl mr-5"
                      :class="message.type === 'in' ? 'rounded-bl-lg' : 'rounded-br-lg'"
                      :style="message.type === 'in' ? 'max-width: 530px; background-color: #F4F6F8;' : 'max-width: 570px; background-color: #F3F4FA;'"
                      style="line-height: 20px; min-width: 100px;"
                    >
                      <div class="message-title-name" v-if="message.caller_first_name || message.caller_last_name">{{ message.caller_first_name + ' ' + message.caller_last_name }}</div>
                      <div v-if="message.attach">
                        {{ message.attach }}
                      </div>
                      <div>
                        {{ getMessageText(message.text) }}
                      </div>
                    </div>

                    <!-- <div
                      class="mt-2 ml-5 fz-12 grey&#45;&#45;text text&#45;&#45;darken-1"
                      v-if="chat.messageList.length === 1 && !inContact"
                    >
                      <div v-if="!message.sms">
                        This number is not in your contacts list.
                        <span
                          class="indigo&#45;&#45;text pa-0 text-decoratio.n-none ml-1 pointer"
                          @click="sendMessageAsSms(index)"
                        >Send this message as SMS.</span>
                      </div>
                      <div
                        class="d-flex fz-12 grey&#45;&#45;text text&#45;&#45;darken-1"
                        v-else
                      >
                        Message sent as SMS.
                      </div>
                    </div> -->
                  </template>

                  <template v-if="message.call">
                    <template v-if="message.call.status === 'incoming'">
                      <div
                        class="d-flex align-center rounded-bl-lg py-4 px-5 rounded-xl pointer"
                        style="line-height: 20px; background-color: #50B83C;"
                        @click="answerCall(message)"
                      >
                        <v-icon size="24px" color="#fff" class="mr-2">phone</v-icon>
                        <div class="d-flex align-center fz-16 white--text">
                          {{ user.firstname && user.firstname.trim() || user.phoneNumber }} is calling you. {{ !activeAnswerButton ? 'Hold other active calls' : 'Click' }} to answer.
                        </div>
                      </div>
                    </template>

                    <template v-if="message.call.status === 'finished'">
                      <div
                        class="d-flex align-center rounded-br-lg py-4 px-5 rounded-xl mr-5"
                        style="line-height: 20px; background-color: #F4F6F8;"
                      >
                        <v-icon size="24px" color="#919EAB" class="mr-2">phone</v-icon>
                        <div class="d-flex flex-column fz-16">
                          Call finished
                        </div>
                      </div>
                    </template>

                    <template v-if="isCallOutbound && getCall && getCall.status === 'calling'">
                      <v-sheet
                        class="fz-12 grey--text text--darken-1 d-flex flex-column align-center justify-center"
                        style="position: absolute; left: 0; top: 0; z-index: 1;"
                        width="100%"
                        max-height="566px"
                        height="100%"
                        color="#ECF6E9"
                      >
                        <div
                          style="width: 80px; height: 80px; border: 2px solid #108043; box-shadow: rgb(187 229 179) 0 0 0 20px; background-color: #fff;"
                          class="rounded-circle d-flex align-center justify-center"
                        >
                          <v-icon size="45px" color="#108043">phone</v-icon>
                        </div>

                        <span class="fz-16 font-weight-semi-bold dark-text mt-10">Calling…</span>
                      </v-sheet>
                    </template>

                    <template>
                      <v-sheet
                        v-if="getCall && getCall.active"
                        class="fz-16 font-weight-semi-bold white--text d-flex align-center px-6"
                        style="position: absolute; left: 0; top: 0;"
                        height="40px"
                        width="100%"
                        color="#50B83C"
                      >
                        <v-icon size="24px" color="#FFF" class="mr-2">phone</v-icon>
                        {{ getActiveCallDurationTime }}
                      </v-sheet>

                      <div v-if="message.call.status === 'answered'"
                           :class="message.type === 'in' ? 'rounded-bl-lg' : 'rounded-br-lg'"
                        class="d-flex align-center py-4 px-5 rounded-xl pointer"
                        style="line-height: 20px; background-color: #5C6AC4;"
                      >
                        <v-icon size="24px" color="#fff" class="mr-2">phone</v-icon>
                        <div class="d-flex align-center fz-16 white--text">
                          Call started
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-if="message.component">
                    <div
                      v-if="message.component.status === 'send'"
                      class="d-flex align-center rounded-l-lg py-4 px-5 rounded-xl mr-5"
                      style="line-height: 20px; background-color: #F4F6F8;"
                    >
                      <v-icon size="24px" color="#919EAB" class="mr-2">send</v-icon>
                0      <div class="d-flex flex-column fz-16">
                        Call sent to Support queue
                      </div>
                    </div>

                    <div
                      v-if="message.component.status === 'record'"
                      class="d-flex align-center rounded-l-lg py-4 px-5 rounded-xl mr-5 pointer"
                      style="line-height: 20px; background-color: #F4F6F8;"
                      @click="recordListen"
                    >
                      <v-icon size="24px" color="#919EAB" class="mr-2">play_arrow</v-icon>
                      <div class="d-flex flex-column fz-16">
                        Call recording available. Click to listen.
                      </div>
                    </div>

                    <v-alert
                      v-if="message.component.alert"
                      close-text="Close Alert"
                      icon="home"
                      class="success-fixed-alert mx-n4 py-6 px-4 d-flex align-center justify-space-between"
                      transition="scroll-y-transition"
                      min-height="70px"
                      max-width="724px"
                      width="100%"
                      style="top: 56px;"
                    >
                      <span class="fz-16 font-weight-bold">Call sent to Support queue</span>

                      <template v-slot:close>
                        <v-icon @click="closeAlert(index)">close</v-icon>
                      </template>
                    </v-alert>
                  </template>
                </div>
                <span class="fz-12 d-flex flex-column align-start text-uppercase text-no-wrap light-text">
                  {{ smsFormatTime(message.created) }}

                  <v-tooltip
                    bottom
                    v-if="message.sms"
                    content-class="v-tooltip--bottom"
                  >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="24px"
                    color="#919EAB"
                    class="mt-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    textsms
                  </v-icon>
                </template>

                <span>Sent as SMS</span>
              </v-tooltip>
                </span>
              </div>
            </template>
          </template>
          <div
            v-if="playedMessage.name !== undefined"
            class="d-flex align-center rounded-l-lg py-4 px-5 rounded-xl mr-5 pointer"
            style="line-height: 20px; background-color: #F4F6F8;"
          >
            <v-icon size="24px" color="#919EAB" class="mr-2">play_arrow</v-icon>
            <div class="d-flex flex-column fz-16">
              Message {{playedMessage.name}} played
            </div>
          </div>
          <template v-else-if="playedMessage.name === undefined && chat.messageList.length === 0">
            <div
              class="fz-12 grey--text text--darken-1 d-flex align-center justify-center text-center"
              style="height: 100%; max-height: 550px;"
            >
              No message history <br> yet
            </div>
          </template>
        </div>
      </v-sheet>

      <v-divider></v-divider>
      <v-col
        cols="12"
        md="12"
        class="py-3 px-4 relative"
        style="flex: 0"
      >
        <v-tooltip top content-class="v-tooltip--top">
          <template v-slot:activator="{ on, attrs }">
            <div class="disable-send-block"
                 v-if="!$store.state.auth.userAccount.campaign_id"
                 @click="enableSms"
                 v-bind="attrs"
                 v-on="on"></div>
          </template>
          <span>Enable Outbound SMS</span>
        </v-tooltip>
        <v-textarea
          class="chat-textarea"
          solo
          outlined
          type="text"
          label="Add message"
          v-model="chat.message"
          dense
          hide-details
          auto-grow
          rows="0"
          append-outer-icon="send"
          v-on:keyup.enter="sendMessage"
        >
          <template v-slot:prepend>
            <v-file-input
              hide-input
              solo
              :disabled="loadingSendSms"
              height="30px"
              class="reset-prepend-outer light"
              style="transform: rotate(45deg)"
              v-model="chat.attachFile"
            ></v-file-input>
          </template>

          <template v-slot:append-outer>
            <v-hover v-slot="{ hover }">
              <v-btn
                :disabled="chat.message.trim().length === 0"
                :loading="loadingSendSms"
                icon
                @click="sendMessage"
                class="p-3"
              >
                <v-icon
                  :color="hover ? '#5C6AC4' : '#919EAB'"
                >
                  send
                </v-icon>
              </v-btn>
            </v-hover>
          </template>
        </v-textarea>
      </v-col>
    </v-card>
  </div>
</template>

<script>
// import MoreActionComponent from '@/components/MoreActionComponent'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
import {
  call,
  callDecline,
  callHangup,
  getSessionById,
  isOutboundCall,
  beep,
  OUTGOING_REQUEST_NAME, callAnswer
} from '../utilities/sessionManager'
import { formatPhone } from '../utilities/formatPhone'

export default {
  name: 'Chat',
  props: {
    // isNew: {
    //   type: Boolean,
    //   default: false
    // },
    isNewDetails: {
      type: Object,
      default: null
    },
    isCalling: {
      type: Boolean,
      default: false
    },
    userData: {
      type: Object,
      default: () => {}
    },
    isVideo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // MoreActionComponent
  },
  data: () => ({
    checkDevice: true,
    loadingSendSms: false,
    selectedDidNumber: '',
    listOfDidNumber: [],
    callStatus: null,
    isNew: false,
    validPhoneLength: 11,
    openCall: false,
    isCallOnHold: true,
    disableHoldButton: true,
    right_box: 'contact_history',
    domain: 'fonio2.sip.stage.opentact.org',
    port: '5443',
    username: '',
    password: '',
    audioConstraints: {
      audio: true,
      video: false
    },
    simpleUser: null,
    plan: {},
    startTimer: false,
    callIsActive: false,
    callReceived: false,
    inContact: false,
    voiceCall: false,
    isRegistered: false,
    user: {
      firstname: '',
      lastname: '',
      phoneNumber: '',
      avatar: '',
      status: null,
      checked: false
    },
    phoneRules: [
      v => !!v || 'Phone number is required',
      v => /^[0-9]+$/.test(v) || 'Phone number must be valid'
    ],
    chat: {
      message: '',
      attachFile: null,
      isCalling: false,
      messageList: [
        // {
        //   user: true,
        //   sms: true,
        //   text: 'Do you want to download free song for ipod? If so, reading this article could save you from getting in to a lot of trouble! Downloading music to your Ipod has more than one pitfall associated with it, and this article will tell you the best way to download free song for Ipod.',
        //   time: '11:29 pm',
        //   created: '2016-08-02T11:23:50.245Z'
        // }
        // {
        //   user: true,
        //   time: '11:29 pm',
        //   created: '2016-08-02T11:23:50.245Z',
        //   call: {
        //     status: 'incoming'
        //   }
        // },
        // {
        //   user: true,
        //   time: '11:29 pm',
        //   created: '2016-08-02T11:23:50.245Z',
        //   component: {
        //     status: 'record',
        //     alert: false
        //   }
        // }
      ]
    }
  }),
  computed: {
    ...mapState('components', [
      'chatView', 'playedMessage', 'userInfo'
    ]),
    ...mapGetters(['currentCompany', 'phoneNumbers']),
    isFreeAccount () { return this.currentCompany?.is_free },
    getSmsList () {
      const phoneNumber = this.getPhoneNumber
      if (phoneNumber && (phoneNumber in this.$store.state.components.chat)) {
        return this.$store.state.components.chat[phoneNumber]
        // if (chat.length !== 0) {
        // return chat.filter(ch => ch.text)
        // }
        // return []
      }
      return []
    },
    getActiveCallDurationTime () {
      const call = this.getCall
      if (call) {
        const d = moment.utc(call.time * 1000)
        return d.format('mm:ss')
      }
      return ''
    },
    getPhoneNumber () {
      return this.getPhoneNumberFromQuery || this.getPhoneNumberFromInput
    },
    getPhoneNumberFromQuery () {
      return this.$route.query ? this.$route.query.n : ''
    },
    getPhoneNumberFromInput () {
      return this.user ? this.user.phoneNumber : ''
    },
    getCall () {
      return this.$store.state.components.calls.find(call => +call.phoneNumber === +this.getPhoneNumber)
    },
    activeAnswerButton () {
      if (this.$store.state.components.calls.length <= 1) {
        return true
      }
      return this.$store.state.components.calls.every(call => {
        if (call.status !== 'answered') return true
        if (+call.phoneNumber === +this.getPhoneNumber) return true
        if (call.type === OUTGOING_REQUEST_NAME) {
          return call.hold
        }
        return call.mute
      })
    },
    isCallOutbound () {
      const call = this.getCall
      if (call) {
        const s = getSessionById(call.id)
        return isOutboundCall(s)
      }
      return undefined
    },
    media () {
      const audio = document.getElementById('remoteAudio')
      const audio2 = document.getElementById('localAudio')
      return {
        stream: this.audioConstraints,
        render: {
          remote: audio,
          local: audio2
        }
      }
    },
    timeDisplay () {
      return this.time.format('mm:ss')
    },

    videoComponent: {
      get () {
        return this.chatView.videoComponent
      },
      set (videoValue) {
        return videoValue
      }
    },
    inviteComponent: {
      get () {
        return this.chatView.inviteComponent
      },
      set (videoValue) {
        return videoValue
      }
    },
    messageComponent: {
      get () {
        return this.chatView.messageComponent
      },
      set (videoValue) {
        return videoValue
      }
    },
    sendComponent: {
      get () {
        return this.chatView.sendComponent
      },
      set (videoValue) {
        return videoValue
      }
    },
    recordComponent: {
      get () {
        return this.chatView.recordComponent
      },
      set (videoValue) {
        return videoValue
      }
    },
    informationComponent: {
      get () {
        return this.chatView.informationComponent
      },
      set (videoValue) {
        return videoValue
      }
    },
    timeline () {
      return this.chat.messageList.slice().reverse()
    },
    fullName () {
      return (this.user.firstname ? this.user.firstname : '') + ' ' + (this.user.lastname ? this.user.lastname : '')
    },
    groupByDate () {
      const messageList = {}
      const number = +this.user.phoneNumber
      this.chat.messageList
        .filter(e => number && ((e.type === 'in' && +e.ani === number) || (e.type === 'out' && +e.dnis === number)))
        .forEach((item) => {
          const date = item.created.split('T')[0]
          if (messageList[date]) {
            messageList[date].unshift(item)
          } else {
            messageList[date] = [item]
          }
        })
      return messageList
    },
    callingIndex () {
      return this.chat.messageList.findIndex(item => item.call && (item.call.status === 'calling' || item.call.status === 'answered'))
    }
  },
  methods: {
    ...mapMutations('components', ['setSubscribeDialog']),
    ...mapActions(['sendSms', 'didNumbersList', 'getMessages']),
    formatPhone,
    formatChatDate (date) {
      const inputDate = new Date(date)
      const today = new Date()

      const isToday = inputDate.getDate() === today.getDate() &&
          inputDate.getMonth() === today.getMonth() &&
          inputDate.getFullYear() === today.getFullYear()
      return isToday ? 'Today' : this.formatDate(date, 'dddd')
    },
    enableSms () {
      if (this.$store.state.auth.currentUser.role !== 'account_admin') return
      this.$router.push('/settings')
    },
    async getNumbers () {
      try {
        const queries = {
          order_dir: 'DESC',
          order_by: 'createdOn'
        }
        const { data } = await this.didNumbersList(queries)
        if (!data || !data.didList) return

        this.listOfDidNumber = data.didList.map(item => item.didNumber)
        if (!this.listOfDidNumber.length) return

        if (this.isFreeAccount) {
          this.$router.push({ name: 'PhoneNumbers' })
          this.setSubscribeDialog(true)
          return
        }
        this.selectedDidNumber = this.listOfDidNumber[0]
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadMessages () {
      try {
        this.loading = true
        const params = {
          smsDir: 'all',
          orderType: 'DESC',
          orderBy: 'created_at'
        }
        const { data } = await this.getMessages(params)
        if (!data || !data.payload || !data.payload.items) return
        this.chat.messageList = data.payload.items
        this.scrollDown()
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    addMessageCall (call) {
      const messageCallList = this.chat.messageList.filter(item => item.call)
      this.voiceCall = !!call
      if (call) {
        this.isNew = false
        if (messageCallList.length === 0 || (messageCallList.length > 0 && messageCallList[messageCallList.length - 1].call.sessionId !== call.id)) {
          const message = {
            user: true,
            type: call.type === OUTGOING_REQUEST_NAME ? 'out' : 'in',
            created: (new Date().toISOString()),
            call: {
              sessionId: call.id,
              status: call.status
            }
          }
          this.chat.messageList.push(message)
          return
        }
        if (messageCallList.length > 0 && messageCallList[messageCallList.length - 1].call.sessionId === call.id) {
          const indexLastCallMessage = this.chat.messageList.findIndex(ml => ml.call && (ml.call.sessionId === call.id))
          this.chat.messageList[indexLastCallMessage].call.status = call.status
        }
        return
      }
      const callMessages = this.chat.messageList.filter(ml => ml.call)
      if (callMessages.length > 0) {
        const lastMessageCall = callMessages[callMessages.length - 1]
        const indexOfLastMessageCall = this.chat.messageList.findIndex(ml => ml.call && ml.call.sessionId === lastMessageCall.call.sessionId)
        if (indexOfLastMessageCall !== -1) {
          this.chat.messageList[indexOfLastMessageCall].call.status = 'finished'
        }
      }
    },
    async initCall () {
      const phone = this.getPhoneNumber
      if (phone) {
        this.isNew = false
        const call = this.getCall
        if (call) {
          this.addMessageCall(call)
          this.voiceCall = true
          this.user = {
            ...this.user,
            ...call
          }
        } else {
          await this.checkAccountByPhoneNumber(phone)
          this.isNew = !this.user.checked
        }
      } else {
        this.isNew = true
      }
    },
    checkIsDeviceEnableOnPC () {
      navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
        this.checkDevice = true
      }).catch((err) => {
        this.checkDevice = false
        console.error(`you got an error: ${err}`)
      })
    },
    smsFormatTime (time) {
      if (!time || time === '') {
        return ''
      }
      return (new Date(time)).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    },
    changePhoneNumber (event) {
      this.user = {
        firstname: '',
        lastname: '',
        phoneNumber: event,
        avatar: '',
        status: null
      }
      this.isNew = true
    },
    checkAccountByPhoneNumber (phoneNumber) {
      this.$store.dispatch('getCallContact', phoneNumber)
        .then((res) => {
          if (!res.data || !res.data.contactList || !res.data.contactList.length) return
          const contact = res.data.contactList[0]
          this.$set(this, 'user', {
            ...this.user,
            phoneNumber: +contact.phoneNumber,
            firstname: contact.firstName || '',
            lastname: contact.lastName || '',
            avatar: contact ? 'https://picsum.photos/200' : '',
            checked: !!contact
          })
        })
        .catch(() => {
          console.log(`Can't find: ${phoneNumber}`)
          this.isNew = true
          this.$set(this, 'user', {
            ...this.user,
            phoneNumber: phoneNumber
          })
        })
    },
    async sendMessage () {
      if ((this.chat.message.trim() || this.chat.attachFile) && this.getPhoneNumber) {
        this.loadingSendSms = true
        try {
          const { data } = await this.sendSms({
            from: this.selectedDidNumber,
            to: this.getPhoneNumber,
            message: this.chat.message
          })
          if (data.payload) {
            this.$store.commit('components/setNewSms', {
              data: {
                ...data.payload,
                text: data.payload.message,
                created: data.payload.created_on,
                caller_first_name: this.$store.state.auth.currentUser.firstName,
                caller_last_name: this.$store.state.auth.currentUser.lastName
              },
              type: 'out'
            })
            this.chat.message = ''
          }
          this.loadingSendSms = false
        } catch (e) {
          this.loadingSendSms = false
          const error = e.error.message
          const errorMessage = error === '[RateLimit:SMS:TNLease] TNLease SMS subscription not found' ? 'TNLease SMS subscription not found' : 'Something wrong'
          this.$snotify.error(errorMessage)
        }
      }
    },
    sendMessageAsSms (index) {
      this.chat.messageList[index].sms = true
    },
    answerCall (message) {
      if (message.call && message.call.sessionId) {
        // this.voiceCall = true
        const s = getSessionById(message.call.sessionId)
        callAnswer(s)
      }
    },

    recordListen () {
      console.log('Play recording')
    },
    closeAlefrt (index) {
      this.chat.messageList[index].component.alert = false
    },
    // inviteComponentButton (payload) {
    //   this.$store.dispatch('components/chatInvite', payload)
    // },
    // messageComponentButton (payload) {
    //   this.$store.dispatch('components/chatMessage', payload)
    // },
    videoComponentButton (payload) {
      this.$store.dispatch('components/chatVideoCall', payload)
      this.$store.dispatch('components/chatSend', false)
      this.$store.dispatch('components/chatRecord', false)
      this.$store.dispatch('components/chatInformation', false)
    },
    sendComponentButton (payload) {
      this.$store.dispatch('components/chatSend', payload)
      this.$store.dispatch('components/chatVideoCall', false)
      this.$store.dispatch('components/chatRecord', false)
      this.$store.dispatch('components/chatInformation', false)
    },
    recordComponentButton (payload) {
      this.$store.dispatch('components/chatRecord', payload)
      this.$store.dispatch('components/chatVideoCall', false)
      this.$store.dispatch('components/chatSend', false)
      this.$store.dispatch('components/chatInformation', false)
    },
    informationComponentButton (payload) {
      this.$store.dispatch('components/chatInformation', payload)
      this.$store.dispatch('components/chatVideoCall', false)
      this.$store.dispatch('components/chatSend', false)
      this.$store.dispatch('components/chatRecord', false)
    },
    informationComponentTrigger () {
      this.informationComponent = !this.informationComponent
      this.informationComponentButton(this.informationComponent)
    },
    async hangUpHelp (isActive, session) {
      if (isActive || isOutboundCall(session)) {
        return await callHangup(session)
      }
      return await callDecline(session)
    },
    muteCall () {

    },
    async hangup () {
      const call = this.getCall
      if (call) {
        const s = getSessionById(call.id)
        const res = await this.hangUpHelp(call.active, s)
        if (res === 'success') {
          this.voiceCall = false
          // this.callStatus = 'ended'
        }
      }
    },

    async toCall () {
      const phone = this.getPhoneNumber
      await this.checkAccountByPhoneNumber(phone)
      if (!this.user.checked) {
        this.$snotify.error('Please check your contact')
        return
      }
      this.voiceCall = true
      phone && call(phone)
      beep(true)
    },

    async hold () {

    },

    getMessageText (text) {
      return text.replace(/\n/g, '&lt;br />')
    },
    scrollDown () {
      this.$nextTick(() => {
        if (this.$refs.msgBlock?.$el) {
          this.$refs.msgBlock.$el.scrollTop = this.$refs.msgBlock.$el?.scrollHeight
        }
      })
    },
    removeMsgBlockScrollListener () {
      if (this.$refs.msgBlock?.$el) {
        this.$refs.msgBlock.$el.removeEventListener('scroll', this.handleScroll)
      }
    }
    // async handleScroll (e) {
    //   console.log(e)
    //   if (this.loading || this.skip + this.take > this.total) return
    //   if (e.target.scrollTop <= 200) {
    //     const oldScrollHeight = e.target.scrollHeight
    //     this.skip += this.take
    //     await this.loadMessages()
    //     this.$nextTick(() => {
    //       const newScrollHeight = e.target.scrollHeight
    //       e.target.scrollTop += newScrollHeight - oldScrollHeight
    //     })
    //   }
    // }
  },
  beforeDestroy () {
    this.removeMsgBlockScrollListener()
  },
  async created () {
    this.checkIsDeviceEnableOnPC()
    await this.initCall()
    // if (Object.keys(this.$store.state.components.userInfo).length === 0) {
    //   router.push({ path: '/' })
    // }
    this.sipAccess = JSON.parse(localStorage.getItem('sipAccess'))
    if (this.isNew) {
      // this.user.firstName = this.user.lastName = ''
      // this.chat.messageList = []
    }
    this.getNumbers()
    this.loadMessages()
    if (this.getPhoneNumberFromQuery) {
      this.user.phoneNumber = this.getPhoneNumberFromQuery
    }
  },
  async mounted () {
    if (this.isVideo) {
      this.$store.dispatch('components/chatVideoCall', true)
    }
    this.$root.$toCall = this.toCall
  },
  watch: {
    getCall (call) {
      this.addMessageCall(call)
    },
    getSmsList (chat) {
      if (chat.length === 0) return
      const lastSms = chat[chat.length - 1]
      console.log('lastSms: ', lastSms)
      this.chat.messageList.push(lastSms)
    },
    getPhoneNumberFromInput (phoneNumber) {
      const n = this.$route.query.n
      if ((n && +n !== +phoneNumber) || !n) {
        this.$router.replace({ query: { n: phoneNumber } })
      }
    },
    getPhoneNumberFromQuery (phoneNumber) {
      if (phoneNumber && phoneNumber !== this.getPhoneNumberFromInput) {
        this.user.phoneNumber = phoneNumber
        if (!this.user.checked) {
          this.initCall()
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep .v-input__append-outer {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .flex-wrap-mobile {
    flex-wrap: wrap !important;
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap !important;
    }
  }

  .chat-textarea::v-deep {
    textarea {
      max-height: 5rem !important;
      overflow: auto !important;
    }
  }
  .message-title-name {
    font-size: smaller;
  }
  .disable-send-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
    z-index: 1;
    cursor: pointer;
  }
</style>
