import { parsePhoneNumberFromString, parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'

function isUSPhoneNumber (number) {
  try {
    if (!number) return ''
    if (number.length === 10) {
      number = '1' + number
    }
    const phoneNumber = parsePhoneNumberFromString('+' + number, 'US')
    return phoneNumber ? phoneNumber.country === 'US' && phoneNumber.isValid() : false
  } catch (error) {
    return false
  }
}

function beautifyPhone (number) {
  try {
    if (!number) return ''
    if (number.length === 10) {
      number = '1' + number
    }
    const beautified = parsePhoneNumber('+' + number)
    return beautified.formatNational()
  } catch (error) {
    return number
  }
}

function formatPhone (number) {
  if (!number) return ''
  number = parseInt(number).toString()
  if (isUSPhoneNumber(number)) {
    return beautifyPhone(number)
  }
  const _number = `+${number}`
  if (isValidPhoneNumber(_number)) {
    return _number
  }
  return number
}

export {
  formatPhone
}
